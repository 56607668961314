<template>
  <v-expansion-panel v-if="loggedRecruiter">
    <expansion-panel-header
      :interview="interviews"
      :interviewId="interviewId"
      :isInterviewer="isInterviewer"
    />
    <v-expansion-panel-content class="mt-12">
      <v-row class="d-flex justify-space-between align-center px-5">
        <!-- <v-col cols="12" md="8" xl="9"> -->
        <div>
          <div v-if="isGroup">
            <v-chip-group
              v-model="selectedCandidate"
              v-if="isGroup"
              active-class="primary text--white"
              mandatory
              column
              hide-details
            >
              <template v-for="candidate in interviews">
                <v-chip
                  :key="
                    candidate.interviewee
                      ? candidate.interviewee.id
                      : candidate.email
                  "
                  :value="candidate"
                  class="ma-2"
                  label
                  :disabled="candidate.blocked"
                  :close="!candidate.interviewee && isInterviewer"
                  close-icon="mdi-pencil"
                  hide-details
                  @click:close="editEmail(candidate.email)"
                >
                  <v-avatar left>
                    <v-img
                      :src="
                        candidate.interviewee && candidate.interviewee.photo
                          ? `${apiUrl}/images/candidate/${candidate.interviewee.photo}`
                          : '/avatar.png'
                      "
                    ></v-img> </v-avatar
                  >{{
                    candidate.interviewee
                      ? splitName(candidate.interviewee.fullName)
                      : candidate.email
                  }}
                </v-chip>
              </template>
            </v-chip-group>
          </div>
        </div>
        <div>
          <div class="d-flex flex-row">
            <v-btn
              v-if="isInterviewer"
              text
              color="primary"
              :disabled="isRunning"
              @click="openOptionsReSchedule()"
            >
              <v-icon>mdi-calendar</v-icon>
              <span>{{ $t("schedule_interview") }}</span>
            </v-btn>
            <v-btn
              v-if="isInterviewer && isStagePending"
              :loading="isLoading2"
              color="primary"
              outlined
              @click="updateState(buttonActions.value)"
            >
              <span>{{ $t(buttonActions.text) }}</span>
              <v-icon>{{ buttonActions.icon }}</v-icon>
            </v-btn>
            <!-- <v-btn
              v-if="isInterviewer || isObserver"
              :loading="isLoading2"
              color="primary"
              outlined
              class="ml-2"
              router
              :to="{ name: 'video.interview', params: { id: interviewId } }"
            >
              <span>{{ $t("live_interview") }}</span>
              <v-icon>mdi-video-outline</v-icon>
            </v-btn> -->
          </div>
        </div>
      </v-row>

      <v-row v-if="selectedCandidate.interviewee" class="px-5">
        <v-btn
          text
          small
          color="primary"
          target="_blank"
          :to="`/pdf/${selectedCandidate.interviewee.id}`"
        >
          <v-icon>mdi-file-account-outline</v-icon>
          <span>{{ $t("view_cv") }}</span>
        </v-btn>
      </v-row>

      <v-row class="px-5">
        <v-col cols="12" md="8" xl="9">
          <classification
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            :isInterviewer="isInterviewer"
            :isObserver="isObserver"
            :selectedStage="selectedStage"
            class="mb-10"
          />

          <comments
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :selectedStage="selectedStage"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            class="mb-12"
          />
        </v-col>

        <v-col cols="12" md="4" xl="3" class="mr-0 pr-0">
          <results
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :selectedStage="selectedStage"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            :isInterviewer="isInterviewer"
            :isRunningButNotJoined="isRunningButNotJoined"
            @chageStage="chageStage"
            @updatedState="isLoading2 = false"
          />

          <div class="whirelab-results mt-3">
            <div class="d-flex flex-column mb-2">
              <div style="font-size: 15px">
                {{ $t("recruitment_stages") }}
                <v-tooltip left>
                  <template v-slot:activator="{ on }">
                    <v-icon small v-on="on" class="ml-2" color="primary">
                      mdi-information-variant-circle-outline
                    </v-icon>
                  </template>
                  <div
                    style="width: 180px;"
                    v-html="$t('See_recruitment_stages_scores')"
                  ></div>
                </v-tooltip>
              </div>
              <div>
                <v-btn
                  small
                  block
                  color="primary"
                  class="mt-2"
                  @click="openAddStage()"
                  >{{ $t("add_new_stage") }}
                  <v-icon small>mdi-plus</v-icon></v-btn
                >
              </div>
            </div>
            <!-- <pre>{{ stages }}</pre> -->
            <div
              v-for="stage in stages"
              :key="stage.name"
              class=" align-center"
            >
              <v-list-item two-line style="padding-left: 0">
                <v-list-item-content>
                  <v-list-item-title class="body-2 font-weight-bold black--text"
                    >{{ stage.name }}
                    <v-tooltip left>
                      <template v-slot:activator="{ on }">
                        <v-icon
                          small
                          v-on="on"
                          class="ml-2 mr-2"
                          color="primary"
                        >
                          mdi-information-variant-circle-outline
                        </v-icon>
                      </template>
                      <div
                        style="width: 180px"
                        v-html="stage.analyze"
                      ></div> </v-tooltip
                  ></v-list-item-title>
                  <span style="font-size: 10.8pt;">
                    {{ $t("start_date_time") }}:</span
                  >
                  <v-list-item-subtitle
                    class=" font-weight-regular"
                    style="font-size: 10pt;"
                  >
                    {{ dateFormat(stage.startDate) }}
                    {{ $t("at_") }}
                    {{ stage.startTime }}
                  </v-list-item-subtitle>
                  <span style="font-size: 10.8pt; margin-top: 2.5%;"
                    >{{ $t("end_date_time") }}:</span
                  >
                  <v-list-item-subtitle
                    class="font-weight-regular"
                    style="font-size: 10pt;"
                  >
                    {{ dateFormat(stage.endDate) }}
                    {{ $t("at_") }}
                    {{ stage.endTime }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <!-- <pre>{{ stage.participants }}</pre> -->
              <P style="margin-top: -3%; font-size: 10pt;"
                >{{ $t("participants") }}:
                <div>
                  <avatar-group :items="stage.participants" />
                </div>
              </P>
              <v-divider style="margin-top: -3%;"></v-divider>
            </div>
          </div>

          <recruter-list
            :interviewId="interviewId"
            :interviews="interviews"
            :selectedCandidate="selectedCandidate"
            :selectedRecruter="selectedRecruter"
            :isCompleted="isCompleted"
            :isRunning="isRunning"
            :isInterviewer="isInterviewer"
            class="mb-1"
            @select="selectRecruter"
          />

          <v-col cols="12" md="12">
            <div
              v-if="!isCompleted && isInterviewer"
              v-show="!isRunning"
              class="text-right mt-0 mb-10"
            >
              <v-btn
                text
                small
                color="primary"
                class="mt-12"
                @click="openCancelDialog()"
              >
                {{ $t("cancel") }}
              </v-btn>
              <v-btn
                small
                color="primary"
                class="mt-12"
                @click="openFinishDialog()"
              >
                {{ $t("finish") }} {{ $t("interview") }}
              </v-btn>
            </div>
          </v-col>
        </v-col>

        <!-- <v-col cols="12" md="12" class="text-right px-10 py-10">
          <v-btn text small color="primary">
            {{ $t("cancel") }}
          </v-btn>
          <v-btn small color="primary">
            {{ $t("finish") }}
          </v-btn>
        </v-col> -->
      </v-row>
    </v-expansion-panel-content>

    <notes
      :dialog="noteDialog"
      :interviewId="interviewId"
      :selectedCandidate="selectedCandidate"
      :isRunning="isRunning"
      @close="noteDialog = false"
    />

    <progress-dialog :processing="isLoading" />

    <stage-dialog
      :dialog="dialogStage"
      :vacancyId="vacancyId"
      :language="getPublishingLang($i18n.locale)"
      @done="stageAdded"
      @close="closeAddStage()"
    >
    </stage-dialog>

    <stage-edit-dialog
      :dialog="dialogStage"
      :vacancyStages="stages"
      :vacancyId="vacancyId"
      @close="dialogStage = false"
      @done="stageUpdated"
    ></stage-edit-dialog>
  </v-expansion-panel>
</template>

<script>
import { API_URL } from "@/api";
import { mapGetters } from "vuex";
import {
  JOIN_OR_LEFT_INTERVIEWER_MUTATION,
  UPDATE_STAGE_RATING_MUTATION,
} from "../../graphql/Mutation.gql";
import { GET_VACANCY_BY_ID_QUERY } from "../../graphql/Query.gql";
import ExpansionPanelHeader from "./ExpansionPanelHeader.vue";
import Classification from "./Classification.vue";
import Results from "./Results.vue";
import RecruterList from "./RecruterList.vue";
import Comments from "./Comments.vue";
import Notes from "./Notes.vue";
import StageDialog from "../StageDialog.vue";
import StageEditDialog from "../StageEditDialog.vue";
import AvatarGroup from "../recruter/AvatarGroup.vue";
import moment from "moment";

export default {
  name: "ExpansionPanel",

  components: {
    ExpansionPanelHeader,
    Classification,
    Results,
    RecruterList,
    Comments,
    Notes,
    StageDialog,
    StageEditDialog,
    AvatarGroup,
  },

  props: {
    interviewId: { type: String },
    vacancyId: { type: String },
    interviews: { type: Array },
    isCompleted: { type: Boolean },
  },

  data: () => ({
    apiUrl: API_URL,
    isStageChange: false,
    noteDialog: false,
    knowledge: 40,
    selectedCandidate: undefined,
    selectedRecruter: undefined,
    selectedStage: undefined,
    loggedRecruiter: null,
    joined: false,
    master: false,
    isLoading: false,
    isLoading2: false,
    vacancy: null,
    language: {},
    dialogStage: false,
  }),

  apollo: {
    vacancy: {
      query: GET_VACANCY_BY_ID_QUERY,
      variables() {
        return {
          id: this.vacancyId,
          fromProfile: true,
        };
      },
      fetchPolicy: "no-cache",
      result({ data, loading }) {
        if (!loading) {
          this.vacancy = data.vacancy;
        }
      },
    },
  },

  watch: {
    interviews(updatedInterview) {
      // const FIRST_ELEMENT = 0;
      this.getCurrentSelected(updatedInterview);
      this.setCurrentSelectedRecruter(updatedInterview);
      this.setLoggedRecruiter(updatedInterview);

      const CURRENT_STAGE = this.getCurrentStage(this.selectedCandidate);
      this.selectedStage = CURRENT_STAGE.name;

      if (CURRENT_STAGE.state === "running") {
        clearInterval(this.cron);
      }
    },

    selectedCandidate(val) {
      const CURRENT_STAGE = this.getCurrentStage(val);

      const currentSelectedRecruter = CURRENT_STAGE.recruters.find(
        (findRecruter) => findRecruter.user.id === this.selectedRecruter.user.id
      );

      if (currentSelectedRecruter) {
        this.selectedRecruter = currentSelectedRecruter;
        this.selectedRecruter.isObserver = false;
      } else {
        this.selectedRecruter = CURRENT_STAGE.observers.find(
          (findObserver) =>
            findObserver.user.id === this.selectedRecruter.user.id
        );
        this.selectedRecruter.isObserver = true;
      }
    },

    loggedRecruiter(val) {
      if (!val.joined) {
        // eslint-disable-next-line no-undef
        Fire.$emit("left");
      }
    },
  },

  created() {
    const FIRST_ELEMENT = 0;
    const CURRENT_STAGE = this.getCurrentStage(this.interviews[FIRST_ELEMENT]);

    this.selectedStage = CURRENT_STAGE.name;

    this.selectedCandidate = this.interviews[FIRST_ELEMENT];
    const logggedRecruter = CURRENT_STAGE.recruters.find(
      (findRecruter) => findRecruter.user.id === this.userId
    );

    if (logggedRecruter) {
      this.selectedRecruter = logggedRecruter;
      this.selectedRecruter.isObserver = false;
    } else if (CURRENT_STAGE.observers.length > 0) {
      this.selectedRecruter = CURRENT_STAGE.observers.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );
      this.selectedRecruter.isObserver = true;
    } else {
      this.selectedRecruter = CURRENT_STAGE.recruters[FIRST_ELEMENT];
    }

    this.setLoggedRecruiter(this.interviews);
  },

  computed: {
    ...mapGetters({
      currentUser: "auth/getCurrentUser",
      getPublishingLang: "library/publishingLang",
    }),

    userId() {
      return this.$root.$data.userId;
    },
    isGroup() {
      return this.interviews.length > 1;
    },
    buttonActions() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );
      if (currentStage.state === "running" && this.isMaster) {
        return { text: this.$t("pause"), icon: "mdi-pause", value: "pause" };
      } else if (
        currentStage.state === "running" &&
        !this.isMaster &&
        !this.loggedRecruiter.joined
      ) {
        return {
          text: this.$t("interviewParticipate"),
          icon: "mdi-play",
          value: "join",
        };
      } else if (
        currentStage.state === "running" &&
        !this.isMaster &&
        this.loggedRecruiter.joined
      ) {
        return {
          text: this.$t("Goout"),
          icon: "mdi-logout",
          value: "left",
        };
      } else if (
        this.isObserver &&
        currentStage.state !== "running" &&
        !this.loggedRecruiter.joined
      ) {
        return {
          text: this.$t("interviewParticipate"),
          icon: "mdi-play",
          value: "join",
        };
      }
      return { text: this.$t("start"), icon: "mdi-play", value: "running" };
    },

    isRunning() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "running" && this.loggedRecruiter.joined;
    },

    isStageRunning() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "running";
    },

    isStagePending() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "pending";
    },

    

    isRunningButNotJoined() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage.state === "running" && !this.loggedRecruiter.joined;
    },

    isInterviewer() {
      const FIRST_ELEMENT = 0;
      const currentStage = this.getCurrentStage(this.interviews[FIRST_ELEMENT]);

      const interviewer = currentStage.recruters.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );

      return !!interviewer;
    },

    isObserver() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      const interviewer = currentStage.observers.find(
        (findObserver) => findObserver.user.id === this.userId
      );

      return !!interviewer;
    },

    isPending() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      const result = currentStage.stage === 'pending';
      console.log({result});

      return result
    },

    isMaster() {
      return this.loggedRecruiter.isMaster;
    },

    getCandidacyIndex() {
      const result = this.vacancy.candidacies.findIndex(
        (candidacy) =>
          candidacy.candidate &&
          candidacy.candidate.id === this.selectedCandidate.interviewee.id
      );
      if (result > -1) {
        return result;
      } else {
        return -1;
      }
    },
    stages() {
      if (this.vacancy) {
        const result = this.vacancy.candidacies.findIndex(
          (candidacy) =>
            candidacy.candidate &&
            candidacy.candidate.id === this.selectedCandidate.interviewee.id
        );

        return this.vacancy.candidacies[result].stages;
      } else {
        return [];
      }
    },
  },

  beforeDestroy() {
    clearInterval(this.cron);
  },

  methods: {
    dateFormat(date) {
      if (date) {
        return moment(date.substring(0, 10))
          .lang(this.$i18n.locale)
          .format("LL");
      }
    },
    openAddStage() {
      this.dialogStage = true;
    },
    async stageUpdated(stages) {
      if (this.vacancy) {
        this.vacancy.candidacies[this.getCandidacyIndex].stages === stages;
        this.dialogStage = false;
        await this.$apollo.queries.vacancy.refetch();
      }
    },
    closeAddStage() {
      this.dialogStage = false;
    },
    stageAdded(newStage) {
      if (this.vacancy) {
        console.log({ newStage });
        this.vacancy.candidacies = this.vacancy.candidacies.map((candidacy) => {
          candidacy.stages.push(newStage);
          return candidacy;
        });
      }
    },
    async changeStage(stageIndex, rating) {
      if (this.isStageChange) {
        try {
          await this.$apollo.mutate({
            mutation: UPDATE_STAGE_RATING_MUTATION,
            variables: {
              id: this.vacancyId,
              stageIndex,
              candidateIndex: this.getCandidacyIndex,
              rating,
            },
          });
        } catch (error) {
          // this.error = formatError(error.message);
          this.showError = true;
        } finally {
          this.isStageChange = false;
        }
      } else {
        this.isStageChange = true;
      }
    },

    editEmail(candidateEmail) {
      const params = {
        interviewId: this.interviewId,
        candidateEmail,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("EditEmailDialog", params);
    },

    getCurrentSelected(updatedInterview) {
      const FIRST_ELEMENT = 0;

      const selectedCandidateIndex = updatedInterview.findIndex(
        (findInterview) =>
          (findInterview.interviewee &&
            this.selectedCandidate.interviewee &&
            findInterview.interviewee.id ===
              this.selectedCandidate.interviewee.id) ||
          (!findInterview.interviewee &&
            !this.selectedCandidate.interviewee &&
            findInterview.email === this.selectedCandidate.email)
      );

      this.selectedCandidate =
        selectedCandidateIndex > -1
          ? updatedInterview[selectedCandidateIndex]
          : updatedInterview[FIRST_ELEMENT];
    },

    setCurrentSelectedRecruter(updatedInterview) {
      const FIRST_ELEMENT = 0;
      const CURRENT_STAGE = this.getCurrentStage(
        updatedInterview[FIRST_ELEMENT]
      );

      let oldSelectedRecruter = CURRENT_STAGE.recruters.find(
        (findRecruter) => findRecruter.user.id === this.selectedRecruter.user.id
      );

      if (oldSelectedRecruter) {
        this.selectedRecruter = oldSelectedRecruter;
        this.selectedRecruter.isObserver = false;
      } else {
        oldSelectedRecruter = CURRENT_STAGE.observers.find(
          (findObserver) =>
            findObserver.user.id === this.selectedRecruter.user.id
        );
        this.selectedRecruter = oldSelectedRecruter;
        this.selectedRecruter.isObserver = true;
      }
    },

    setLoggedRecruiter(interview) {
      const FIRST_ELEMENT = 0;
      const CURRENT_STAGE = this.getCurrentStage(interview[FIRST_ELEMENT]);

      const logggedRecruiter = CURRENT_STAGE.recruters.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );

      if (logggedRecruiter) {
        const isMaster = CURRENT_STAGE.master === this.userId;
        this.loggedRecruiter = logggedRecruiter;
        this.loggedRecruiter.isMaster = isMaster;
      } else {
        this.loggedRecruiter = CURRENT_STAGE.observers.find(
          (findObserver) => findObserver.user.id === this.userId
        );
      }
    },

    chageStage(stage) {
      const FIRST_ELEMENT = 0;
      const logggedRecruter = stage.recruters.find(
        (findRecruter) => findRecruter.user.id === this.userId
      );

      this.selectedStage = stage.name;

      if (logggedRecruter) {
        this.selectedRecruter = logggedRecruter;
        this.selectedRecruter.isObserver = false;
      } else if (stage.observers.length > 0) {
        this.selectedRecruter = stage.observers.find(
          (findRecruter) => findRecruter.user.id === this.userId
        );
        this.selectedRecruter.isObserver = true;
      } else {
        this.selectedRecruter = stage.recruters[FIRST_ELEMENT];
      }
    },

    getCurrentStage(interview) {
      return interview.stages.find(
        (findStage) => findStage.name === interview.currentStage
      );
    },

    selectRecruter(recruter) {
      this.selectedRecruter = recruter;
    },

    updateState(state) {
      console.log("state: ", state);
      if (state === "join") {
        this.joinOrLeft(true);
      } else if (state === "left") {
        this.joinOrLeft(false);
      } else {
        this.isLoading2 = true;
        if (state === "running") {
          // eslint-disable-next-line no-undef
          Fire.$emit("startOrPauseInterviewInResultComponents", "pause");
          // eslint-disable-next-line no-undef
          Fire.$emit("joined");
        }

        if (state === "pause") {
          // eslint-disable-next-line no-undef
          Fire.$emit("startOrPauseInterviewInResultComponents", state);
          // eslint-disable-next-line no-undef
          Fire.$emit("left");
        }
      }
    },

    openOptionsReSchedule() {
      const params = {
        selectedCandidate: this.selectedCandidate,
        interviews: this.interviews,
        interviewId: this.interviewId,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("OptionsReSchedule", params);
    },

    openNotes() {
      this.noteDialog = true;
    },

    openFinishDialog() {
      const params = {
        interviewId: this.interviewId,
        interviews: this.interviews,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("FinishInterview", params);
    },

    openCancelDialog() {
      const params = {
        interviewId: this.interviewId,
        interviews: this.interviews,
      };
      // eslint-disable-next-line no-undef
      Fire.$emit("CancelInterview", params);
    },

    splitName(name) {
      return name.split(" ")[0];
    },

    async joinOrLeft(isJoin) {
      const FIRST_ELEMENT = 0;
      this.isLoading2 = true;
      const candidates = this.getCandidateIdsFromInterviews();
      const stage = this.interviews[FIRST_ELEMENT].currentStage;
      try {
        await this.$apollo.mutate({
          mutation: JOIN_OR_LEFT_INTERVIEWER_MUTATION,
          variables: {
            interviewUpdateStateInput: {
              interviewId: this.interviewId,
              candidates,
              state: "running",
              stage,
              isJoin,
            },
          },
        });
        if (isJoin) {
          // eslint-disable-next-line no-undef
          Fire.$emit("joined");
        } else {
          // eslint-disable-next-line no-undef
          Fire.$emit("left");
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading2 = false;
      }
    },

    getCandidateIdsFromInterviews() {
      const candidates = this.interviews.map((mapInterview) => {
        return mapInterview.interviewee
          ? mapInterview.interviewee.id
          : mapInterview.email;
      });

      return candidates;
    },

    fullCurrentStage() {
      const currentStage = this.selectedCandidate.stages.find(
        (findStage) => findStage.name === this.selectedCandidate.currentStage
      );

      return currentStage;
    },
  },
};
</script>

<style scoped>
.results {
  background-color: #daf4fd;
}

.whirelab-results {
  background-color: #daf4fd;
  padding: 20px;
  border-radius: 10px;
}
</style>
